<template>
<section class="Slager">
   <div class="banner" :class="{'enbanner':$i18n.locale == 'en'}">
      <a :href="pomaUrl">{{ $t("PC.Product.Slager.z3x2z7") }}</a>
      <router-link class="price" to="SlagerPrice">{{
        $t("PC.Product.Slager.a4q7q8")
      }}</router-link>
   </div>
   <section class="main_contain">
      <figure class="fg1">
         <figcaption>{{ $t("PC.Product.Slager.v1zo8l") }}</figcaption>
         <p>
            {{ $t("PC.Product.Slager.mz3h79") }}
         </p>
         <div class="box_contain">
            <div class="box_content">
               <svg class="iconsymbol" aria-hidden="true">
                  <use xlink:href="#iconslager_gaoxiao1"></use>
               </svg>
               <p class="title">{{ $t("PC.Product.Slager.he-zuo") }}</p>
               <p class="subtitle">{{ $t("PC.Product.Slager.6z4xdp") }}</p>
            </div>
            <div class="box_content">
               <svg class="iconsymbol" aria-hidden="true">
                  <use xlink:href="#iconslager_gaoxiao"></use>
               </svg>
               <p class="title">{{ $t("PC.Product.Slager.zaaxhj") }}</p>
               <p class="subtitle">{{ $t("PC.Product.Slager.dgu1nr") }}</p>
            </div>
            <div class="box_content">
               <svg class="iconsymbol" aria-hidden="true">
                  <use xlink:href="#iconslager_work"></use>
               </svg>
               <p class="title">{{ $t("PC.Product.Slager.3bwoso") }}</p>
               <p class="subtitle">{{ $t("PC.Product.Slager.jbmulg") }}</p>
            </div>
            <div class="box_content">
               <svg class="iconsymbol" aria-hidden="true">
                  <use xlink:href="#iconslager_gongxiang"></use>
               </svg>
               <p class="title">{{ $t("PC.Product.Slager.4xp0f3") }}</p>
               <p class="subtitle">{{ $t("PC.Product.Slager.nk7i2t") }}</p>
            </div>
         </div>
      </figure>
      <div class="fg2bg">
         <figure class="fg2">
            <figcaption :class="{'enbreak':$i18n.locale=='en'}">{{ $t("PC.Product.Slager.4sxowr") }}</figcaption>
            <p style="text-align: center;">
               {{ $t("PC.Product.Slager.sugyqn") }}
            </p>
            <div class="block_contain">
               <div class="block_content">
                  <svg class="iconsymbol" aria-hidden="true">
                     <use xlink:href="#iconslager_paiban"></use>
                  </svg>
                  <p class="title">{{ $t("PC.Product.Slager.nrp7mg") }}</p>
                  <p class="subtitle">
                     {{ $t("PC.Product.Slager.1m2ing") }}
                  </p>
               </div>
               <div class="block_content">
                  <svg class="iconsymbol" aria-hidden="true">
                     <use xlink:href="#iconslager_aishibie"></use>
                  </svg>
                  <p class="title">{{ $t("PC.Product.Slager.m1esmc") }}</p>
                  <p class="subtitle">
                     {{ $t("PC.Product.Slager.cmp71p") }}
                  </p>
               </div>
               <div class="block_content">
                  <svg class="iconsymbol" aria-hidden="true">
                     <use xlink:href="#iconslager_zhuanhuan"></use>
                  </svg>
                  <p class="title">Word-to-LaTeX</p>
                  <p class="subtitle">
                     {{ $t("PC.Product.Slager.61zxzb") }}
                  </p>
               </div>
               <div class="block_content">
                  <svg class="iconsymbol" aria-hidden="true">
                     <use xlink:href="#iconslager_xiezuo"></use>
                  </svg>
                  <p class="title">{{ $t("PC.Product.Slager.y6x0rr") }}</p>
                  <p class="subtitle">
                     {{ $t("PC.Product.Slager.tkditn") }}
                  </p>
               </div>
               <div class="block_content">
                  <svg class="iconsymbol" aria-hidden="true">
                     <use xlink:href="#iconslager_moban"></use>
                  </svg>
                  <p class="title">{{ $t("PC.Product.Slager.zrx1bp") }}</p>
                  <p class="subtitle">
                     {{ $t("PC.Product.Slager.u2fbb5") }}
                  </p>
               </div>
               <div class="block_content">
                  <svg class="iconsymbol" aria-hidden="true">
                     <use xlink:href="#iconslager_linggan"></use>
                  </svg>
                  <p class="title">{{ $t("PC.Product.Slager.0h8eby") }}</p>
                  <p class="subtitle">
                     {{ $t("PC.Product.Slager.9uh8p9") }}
                  </p>
               </div>
            </div>
         </figure>
      </div>
      <FooterC></FooterC>
   </section>
</section>
</template>

<script>
import FooterC from "../components/FooterC";

export default {
   components: {
      FooterC
   },
   data() {
      return {
         pomaUrl: Global.SLAGER_URL,
         banner_en: require("../../../assets/en/images/banner1.jpg"),
         banner_zh: require("../../../assets/zh/images/banner1.jpg"),
      };
   },
};
</script>

<style lang="scss" scoped>
.Slager {
   margin-top: 70Px;
   background-color: #fff;
   word-break: break-word !important;

   .banner {
      display: block;
      position: absolute;
      left: 50%;
      top: 70Px;
      transform: translate(-50%, 0);
      overflow: hidden;
      font-family:   PingFangSC-Semibold, PingFang SC;
      width: 1920Px;
      height: 440Px;
      background-image: url("../../../assets/zh/images/banner1.jpg");
      background-repeat: no-repeat;
      background-size: contain;

      a {
         font-family:   PingFangSC-Semibold, PingFang SC;
         position: absolute;
         top: 256Px;
         left: 480Px;
         width: 120Px;
         height: 36Px;
         line-height: 36Px;
         border-radius: 2Px;
         box-sizing: border-box;
         text-align: center;
         font-size: 14Px;
         color: #fff;
         border: 1Px solid #fff;

         &:hover {
            cursor: pointer;
         }
      }

      .price {
         font-family:   PingFangSC-Semibold, PingFang SC;
         position: absolute;
         top: 256Px;
         left: 617Px;
         width: 120Px;
         height: 36Px;
         line-height: 36Px;
         background: transparent;
         border-radius: 2Px;
         box-sizing: border-box;
         text-align: center;
         font-size: 14Px;
         color: #fff;
         border: 1Px solid #ffffff;

         &:hover {
            cursor: pointer;
         }
      }
   }

   .enbanner {
      background-image: url("../../../assets/en/images/banner1.jpg");
   }

   .main_contain {
      position: absolute;
      background-color: #fff;
      top: 510Px;
      width: 100%;

      .fg1 {
         margin: 0 auto;
         width: 960Px;
         background-color: #fff;

         &>p {
            width: 960Px;
            font-size: 24Px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 40Px;
         }

         figcaption {
            padding: 72Px 0 24Px;
            font-size: 32Px;
            color: #333333;
            line-height: 58Px;
            text-align: center;
         }

         .box_contain {
            padding: 48Px 0 0 0;
            display: flex;
            justify-content: space-between;
            padding-bottom: 72Px;

            .box_content {
               width: 250Px;
               text-align: center;

               .iconsymbol {
                  width: 116Px;
                  height: 116Px;
                  margin-bottom: 18Px;
               }

               .title {
                  font-size: 22Px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                  line-height: 28Px;
                  margin-bottom: 8Px;
               }

               .subtitle {
                  font-size: 16Px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 22Px;
               }
            }
         }
      }

      .fg2bg {
         background: #f5f6f9;

         .fg2 {
            margin: 0 auto;
            width: 960Px;

            figcaption {
               padding: 72Px 0 24Px;
               font-size: 32Px;
               color: #333333;
               line-height: 58Px;
               text-align: center;
            }

            .enbreak {
               width: 650Px;
               margin: 0 auto;
            }

            &>p {
               width: 960Px;
               font-size: 24Px;
               font-family: PingFangSC-Medium, PingFang SC;
               font-weight: 500;
               color: #333333;
               line-height: 40Px;
            }

            .block_contain {
               display: flex;
               justify-content: space-between;
               flex-wrap: wrap;
               box-sizing: border-box;
               padding: 40Px 0;

               .block_content {
                  width: 466Px;
                  height: auto;
                  background: #ffffff;
                  border-radius: 4Px;
                  padding: 28Px;
                  box-sizing: border-box;
                  margin-bottom: 32Px;

                  .iconsymbol {
                     width: 70Px;
                     height: 70Px;
                     margin-bottom: 16Px;
                  }

                  .title {
                     font-size: 22Px;
                     font-family: PingFangSC-Medium, PingFang SC;
                     font-weight: 500;
                     color: #333333;
                     line-height: 28Px;
                     margin-bottom: 8Px;
                  }

                  .subtitle {
                     font-size: 16Px;
                     font-family: PingFangSC-Regular, PingFang SC;
                     font-weight: 400;
                     color: #666666;
                     line-height: 30Px;
                  }
               }
            }
         }
      }
   }
}
</style>
